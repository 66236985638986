import React from "react"
import { graphql } from "gatsby"

import { GridRow, IconGrid, SEO, PageHeader } from "components"

import CollageSlice from "./_collageSlice"
import MapBlock from "./_mapBlock"
import RichTextSlice from "./_richTextSlice"
import SingleImageSlice from "./_singleImageSlice"

function GenericPage({ data }) {
  return (
    <>
      <SEO
        title={
          (data.page.data.seo_title && data.page.data.seo_title.text) ||
          "Teine Energy"
        }
        description={
          data.page.data.seo_description && data.page.data.seo_description.text
        }
        imageOpenGraph={
          data.page.data.main_image && data.page.data.main_image.url
        }
        imageTwitter={
          data.page.data.main_image && data.page.data.main_image.url
        }
        imageAlt={data.page.data.main_image && data.page.data.main_image.alt}
      />

      {/* Title card text block. */}
      <PageHeader
        data={{
          heading: data.page.data.subtitle && data.page.data.subtitle.html,
          description:
            data.page.data.subtitle_text_box &&
            data.page.data.subtitle_text_box.html,
          image: data.page.data.main_image,
          bottomBackground:
            data.page.data.body &&
            data.page.data.body[0] &&
            data.page.data.body[0].primary &&
            data.page.data.body[0].primary.background_color,
        }}
      />

      {data.page.data.body &&
        data.page.data.body.length > 0 &&
        data.page.data.body.map(({ slice_type, primary, items }, index) => {
          switch (slice_type) {
            // Block of text with an optional header. Optional image below the text.
            case "text":
              return (
                <RichTextSlice
                  data={{
                    content: primary.richtext && primary.richtext.html,
                    backgroundColor: primary.background_color,
                    buttonLabel: primary.button_name,
                    buttonLink: primary.button_url,
                    width: primary.text_box_width,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "collage":
              return (
                <CollageSlice
                  data={{
                    primaryImage: primary.primary_image,
                    secondaryImage: primary.secondary_image,
                    topBackground:
                      data.page.data.body[index - 1] &&
                      data.page.data.body[index - 1].primary &&
                      data.page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      data.page.data.body[index + 1] &&
                      data.page.data.body[index + 1].primary &&
                      data.page.data.body[index + 1].primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "image_with_caption":
              return (
                <SingleImageSlice
                  data={{
                    image: primary.image,
                    topBackground:
                      data.page.data.body[index - 1] &&
                      data.page.data.body[index - 1].primary &&
                      data.page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      data.page.data.body[index + 1] &&
                      data.page.data.body[index + 1].primary &&
                      data.page.data.body[index + 1].primary.background_color,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "factors":
              return (
                <IconGrid
                  title={primary.heading.text}
                  data={{
                    items: items,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "grid_row":
              return (
                <GridRow
                  data={{
                    heading: primary.heading.text,
                    body: primary.grid_text.html,
                    button: primary.button_label,
                    buttonLink: primary.button_link && primary.button_link.url,
                    imageOne: primary.primary_image,
                    imageTwo: primary.secondary_image,
                    topBackground:
                      data.page.data.body[index - 1] &&
                      data.page.data.body[index - 1].primary &&
                      data.page.data.body[index - 1].primary.background_color,
                    bottomBackground:
                      data.page.data.body[index + 1] &&
                      data.page.data.body[index + 1].primary &&
                      data.page.data.body[index + 1].primary.background_color,
                    index: index,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            case "map_block":
              return (
                <MapBlock
                  data={{
                    items: items,
                  }}
                  key={"slice" + slice_type + index}
                />
              )
            default:
              return null
          }
        })}
    </>
  )
}

// Access Prismic data, to feed into page.
export const query = graphql`
  query PageQuery($uid: String!) {
    page: prismicPage(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        subtitle {
          html
        }
        subtitle_text_box {
          html
        }
        main_image {
          url
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        body {
          ... on PrismicPageBodyGridRow {
            slice_type
            id
            primary {
              background_color
              heading {
                text
              }
              grid_text {
                html
              }
              button_label
              button_link {
                url
              }
              primary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              secondary_image {
                fluid(maxWidth: 512) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyText {
            slice_type
            primary {
              background_color
              text_box_width
              richtext {
                html
              }
              button_name
              button_url {
                url
              }
            }
          }
          ... on PrismicPageBodyFactors {
            slice_type

            items {
              graphic {
                fluid(maxWidth: 192) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
              heading {
                text
              }
              description {
                html
              }
            }
            primary {
              background_color
              heading {
                text
              }
            }
          }
          ... on PrismicPageBodyCollage {
            slice_type
            primary {
              primary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
              secondary_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicPageBodyImageWithCaption {
            slice_type
            primary {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
          ... on PrismicPageBodyMapBlock {
            slice_type
            items {
              operation_title {
                text
              }
              operation_description {
                html
              }
              operation_id
            }
          }
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
      }
    }
  }
`

export default GenericPage
